// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  $contrast: color-yiq($color); // Boosted mod: ensure contrast
  #{$parent} {
    color: $color !important;
    background-color: $contrast; // Boosted mod: ensure contrast
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus() {
        // Boosted mod: ensure contrast
        @if $contrast == $white {
          color: darken($color, $emphasized-link-hover-darken-percentage) !important;
        } @else {
          color: lighten($color, $emphasized-link-hover-darken-percentage) !important;
        }
        // end mod
      }
    }
  }
  @include deprecate("`text-emphasis-variant()`", "v4.4.0", "v5", $ignore-warning);
}
