//
// Orange Supra navbar
//

.supra {
  display: none;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $gray-900;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  .navbar-nav {
    margin: 0 -#{$navbar-supra-padding-x};
  }

  .nav-link {
    padding: .75rem $navbar-supra-padding-x #{map-get($spacers, 2) + $navbar-supra-padding-y};
    font-size: $font-size-sm;
    line-height: 1;

    [class*="icon-"] {
      font-size: $h6-font-size;
    }

    &.active::before {
      right: $navbar-supra-padding-x;
      bottom: -#{map-get($spacers, 2) / 10};
      left: $navbar-supra-padding-x;
    }

    &[class*="svg-"] {
      padding: $spacer / 5 map-get($spacers, 2);

      &::after {
        width: $navbar-supra-icon-size;
        height: $navbar-supra-icon-size;
      }
    }
  }

  .dropdown-toggle {
    padding: .75rem $navbar-supra-padding-x #{map-get($spacers, 2) + $navbar-supra-padding-y} map-get($spacers, 2);
    font-size: $font-size-sm;
    line-height: 1;

    &::after {
      border-width: $border-width * 2.5;
      transform: none;
    }

    [class*="icon-"] {
      font-size: $h6-font-size;
    }
  }

  .nav-item + * > {
    .nav-icon,
    .dropdown-toggle {
      margin-left: map-get($spacers, 2);
    }
  }

  .badge {
    top: 0;
    right: -#{map-get($spacers, 1)};
  }
}
