// Orange switches
//
// @note Deprecated since v4.2.1
// @note To be removed in v5

.o-switch {
  .toggle {
    position: relative;
    display: block;
    width: auto;
    min-width: 3.75rem;
    height: 1.875rem;
    margin: map-get($spacers, 1) 0;
    background: $gray-700;
    border: $border-width solid $gray-700;
    outline-offset: $border-width * 2;
    @include transition($transition-focus);

    &::before {
      position: absolute;
      left: 0;
      width: $o-switches-size;
      height: $o-switches-size;
      content: "";
      background: $white;
    }
  }

  .on,
  .off {
    display: none;
    line-height: $o-switches-size;
    color: $white;
    opacity: 0;

    &::after {
      position: absolute;
      top: .1875rem;
      background-position: 50%;
      background-size: $custom-control-indicator-bg-size;
    }
  }

  .off {
    display: block;
    text-align: right;
    opacity: 1;
  }

  .on::after {
    left: .25rem;
  }

  .off::after {
    right: .25rem;
  }

  .toggle-rounded,
  .toggle-rounded::before {
    @include border-radius($o-switches-size, $o-switches-size);
  }

  .checkbox {
    &:focus + .toggle {
      outline: $border-width solid $gray-700;
      outline-offset: -$border-width;
    }

    &:focus:checked + .toggle {
      outline-color: theme-color("primary");
    }

    .js-focus-visible &:focus:not([data-focus-visible-added]) + .toggle {
      outline: 0;
    }

    &:checked + .toggle {
      background: theme-color("primary");
      border-color: theme-color("primary");

      .on {
        display: block;
        opacity: 1;
      }

      .off {
        display: none;
        opacity: 0;
      }

      &::before {
        right: 0;
        left: auto;
      }
    }
  }

  .success:checked + .toggle {
    background: theme-color("success");
    border-color: theme-color("success");

    .on {
      color: color-yiq(theme-color("success"));
    }
  }

  .success:focus:checked + .toggle {
    outline-color: theme-color("success");
  }

  .form-group & .toggle {
    padding: 0 .375rem;
  }

  .form-group & .toggle-rounded {
    padding: 0 .875rem;
  }

  // 3 way toggles
  &.btn-group-toggle {
    display: inline-flex;
    justify-content: flex-start;
    border: $border-width solid $gray-500;

    .btn {
      min-width: 2.5rem;
      padding-right: 0;
      padding-left: 0;
      margin: -$border-width 0;
      border: $border-width solid transparent;
      @include transition($transition-focus); // Boosted mod

      &:first-child {
        margin-left: -$border-width;
      }

      &:last-child {
        margin-right: -$border-width;
      }

      &.active {
        color: $white;
        background-color: $black;
        border-color: $black;
      }

      &:focus,
      &.focus {
        // stylelint-disable-next-line declaration-no-important
        outline: $border-width solid theme-color("primary") !important;
        outline-offset: -$border-width;
      }

      &:active {
        border-color: theme-color("primary");
      }

      &:hover {
        &:not(:active) {
          color: theme-color("primary");
          background-color: transparent;
        }

        &.active {
          color: $white;
          background-color: $black;
          border-color: $black;
        }
      }
    }
  }
}

