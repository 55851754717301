@mixin badge-variant($bg) {
  // Boosted mod: ensure contrast
  $contrast: color-yiq($bg);
  color: $contrast;
  // end mod
  background-color: $bg;

  @at-root a#{&} {
    @include hover-focus() {
      // Boosted mod: ensure contrast
      color: $contrast;
      @if $contrast == $white {
        background-color: darken($bg, 10%);
      } @else {
        background-color: lighten($bg, 10%);
      }
      // end mod
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    }
  }
}
