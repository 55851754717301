//
//  Orange forms
//
// stylelint-disable selector-no-qualifying-type

.form-group {
  input[type="radio"],
  input[type="checkbox"] {
    width: auto;
  }

  .invalid-feedback {
    margin-top: .8125rem;
    font-weight: $font-weight-bold;
  }

  &.top {
    display: flex;
    flex-direction: column;

    .col-form-label {
      margin-bottom: 0;
    }

    .form-control {
      order: 2;
    }
    .invalid-feedback {
      order: 1;
      margin-top: 0;
      margin-bottom: .8125rem;
      line-height: 1;
    }
  }

  &.right {
    display: flex;
    flex-flow: row wrap;

    label,
    .col-form-label {
      min-width: 100%;
    }

    .form-control-message,
    .invalid-feedback {
      max-width: 50%;
      margin-left: .625rem;
    }
  }

  &.row {
    &.top {
      flex-direction: row;

      label,
      .col-form-label {
        order: 1;
      }

      .form-control {
        order: 2;
        margin-top: 0;
      }

      .invalid-feedback {
        order: 0;
      }
    }

    &.right {
      label,
      .col-form-label {
        min-width: auto;
      }

      .invalid-feedback {
        align-self: center;
        margin-top: 0;
      }
    }
  }

  &.is-required label::after {
    margin-left: 6px;
    font-weight: $font-weight-bold;
    color: $orange-2;
    content: "*";
  }

  // browser reset
  .form-control:invalid {
    outline: 0;
    box-shadow: none;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: auto;
    padding-top: .4375rem;
    padding-bottom: .4375rem;

    &.form-control-lg {
      padding-top: .65625rem;
      padding-bottom: .65625rem;
    }

    &.form-control-sm {
      padding-top: .1875rem;
      padding-bottom: .1875rem;
    }
  }
}

// Reposition feedback icon if input has visible label above
.has-feedback label {
  ~ .invalid-feedback {
    top: ($line-height-base + .5); // Height of the `label` and its margin
  }
}
