.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  @include font-size($breadcrumb-font-size);
  // Boosted mod
  font-weight: $font-weight-bold;
  line-height: $breadcrumb-line-height;
  // end mod
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
  a {
    text-decoration: if($link-decoration == underline, null, underline); // Boosted mod: links are perceivable globally
  }

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      float: left; // Suppress underlining of the separator in modern browsers

      /* rtl:ignore */
      padding-left: map-get($spacers, 2); // Boosted mod: RTL
      color: $breadcrumb-divider-color;
      content: escape-svg($breadcrumb-divider);

      /* rtl:remove */
      transform: rotate(180deg); // Boosted mod

      // Boosted mod
      .bg-dark & {
        content: escape-svg($breadcrumb-divider-dark);
      }
      // End mod
    }
  }

  // Boosted mod: list markup is required
}
