// Square list
// @note markers' color are handled in _reboot.scss since it should be globally applied
// @todo Remove this helper when ::marker support is OK
// @see https://caniuse.com/#search=%3A%3Amarker

.o-square-list {
  padding-left: 0;
  list-style-type: none;

  ul {
    padding-left: 1rem;
    list-style-type: inherit;
  }

  li::before {
    margin-right: map-get($spacers, 2);
    font-size: $font-size-sm;
    content: "\25A0";
  }
}
