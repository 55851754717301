// stylelint-disable declaration-no-important

// Contextual backgrounds

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  $contrast: color-yiq($color); // Boosted mod: ensure contrast
  #{$parent} {
    color: $contrast !important; // Boosted mod: ensure contrast
    background-color: $color !important;

    @if $contrast != $black {
      a {
        color: $contrast;
      }
    }
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus() {
      // Boosted mod: ensure contrast
      @if $contrast == $white {
        background-color: darken($color, 10%) !important;
      } @else {
        background-color: lighten($color, 10%) !important;
      }
      // end mod
    }
  }
  @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

@mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  }
  @include deprecate("The `bg-gradient-variant` mixin", "v4.4.2", "v5", $ignore-warning);
}
