.skiplinks {
  position: fixed;
  top: 0;
  z-index: $zindex-skiplinks;

  a {
    outline-offset: $border-width * 6;
    box-shadow: 0 0 0 $border-width * 2 $black;
  }

  a:focus {
    font-weight: $font-weight-bold;
    color: $orange-2;
    background-color: $black;
    outline-offset: $border-width * 2;
  }
}
