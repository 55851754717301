// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  // Boosted mod: disallow functionnal colors for background
  @if ($value != $success) and ($value != $danger) and ($value != $info) and ($value != $warning) {
    @include bg-variant(".bg-#{$color}", $value, true);
  }
}

// Boosted mod: supporting colour as background
@each $color, $value in $supporting-colors {
  @include bg-variant(".bg-#{$color}", $value);
}
// end mod

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value, true);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}
