// stylelint-disable declaration-no-important
//
// Carousel
//

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: subtract(50%, 10px) !important;
  bottom: 0;
  z-index: 1;
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: rgba($white, .5) no-repeat calc(50% - .125rem) 50% / 1.5rem 1.5rem; // stylelint-disable-line function-disallowed-list
  background-image: escape-svg($carousel-control-prev-icon-bg) !important;
  @include border-radius(50%, 50%);
  @include transition($transition-focus);

  &:hover,
  &:focus {
    background-color: $black;
    background-image: escape-svg($carousel-control-prev-icon-hover-bg) !important;
  }

  &:focus {
    outline: $border-width solid $input-focus-border-color;
    outline-offset: -#{$border-width};
  }

  .js-focus-visible &:focus:not([data-focus-visible-added]) {
    outline: 0;
  }

  &:active {
    background-color: $primary;
  }

  &.swiper-button-disabled {
    background-image: escape-svg($carousel-control-prev-icon-disabled-bg) !important;
    opacity: 1;
  }
}

.swiper-button-prev {
  right: auto !important;
  left: map-get($spacers, 2) !important;

  /* rtl:raw:
  transform: rotate(180deg);
  */
}

.swiper-button-next {
  right: map-get($spacers, 2) !important;
  left: auto !important;

  /* rtl:remove */
  transform: rotate(180deg);
}

.swiper-container {
  width: 100% !important;
  margin-bottom: map-get($spacers, 4);
  overflow: hidden;

  .swiper-pagination {
    right: auto;
    bottom: 1rem;
    left: 50%;
    display: flex;
    justify-content: center;
    width: auto;
    padding: $carousel-indicator-spacer 0;
    background-color: rgba($white, .5);
    transform: translateX(-50%);
    @include border-radius($spacer, $spacer);

    .swiper-pagination-bullet {
      box-sizing: content-box;
      flex: 0 1 auto;
      width: $carousel-indicator-width;
      height: $carousel-indicator-width;
      margin-right: $carousel-indicator-spacer;
      margin-left: $carousel-indicator-spacer;
      background-clip: padding-box;
      border: $border-width solid transparent;
      opacity: 1;

      &:hover,
      &:focus {
        border-color: $carousel-control-color;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $carousel-indicator-active-bg;
      border-color: $carousel-control-color;

      &:hover,
      &:focus {
        background-color: $carousel-control-color;
      }
    }
  }
}

.swiper-slide {
  .card {
    border: 0;
  }

  .card-img {
    padding: 0;

    img {
      width: 100%;
      height: auto;
      max-height: 40vh;
      object-fit: cover;
    }
  }

  .card-body {
    @extend .carousel-caption;
  }
}

@include media-breakpoint-down(md) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-container {
    overflow-x: hidden;
    overflow-y: visible;/* used for displaying pagination outside of container */
  }

  .swiper-slide {
    width: 80%;

    .card-img img {
      height: auto;
    }

    .card-body {
      position: static;
    }
  }
}
