// small device display as card
// stylelint-disable selector-no-qualifying-type
@include media-breakpoint-down(md) {
  .o-responsive-table {
    thead {
      display: none;
    }
    tbody,
    tr,
    th,
    td {
      display: block;
    }
    // for tablet, display 2 cards by rows
    @include media-breakpoint-up(md) {
      tr {
        float: left;
        width: 45%;
      }
      tr:nth-child(odd) {
        margin-right: 10%;
      }
    }
    th,
    td {
      vertical-align: middle;
    }
    td[data-title]::before {
      float: left;
      width: 50%;
      font-weight: $font-weight-bold;
      content: attr(data-title);
    }
    tr {
      margin-bottom: 2em;
      border-right: 0;
      border-left: 0;
    }
    tbody {
      th[scope="row"] {
        font-weight: $font-weight-bold;
        border-top: 0;
        border-bottom: 2px solid $gray-500;
      }
    }
  }
}
