//
// o-scroll-up
//

.o-scroll-up {
  position: fixed;
  right: 0;
  bottom: 2.5rem;
  z-index: $zindex-o-scroll-up;
  display: inline-flex;
  align-items: center;
  padding-left: map-get($spacers, 2);
  font-weight: $font-weight-bold;
  text-decoration: if($link-decoration == none, null, none);
  background-color: rgba($white, .8);

  &:hover {
    text-decoration: if($link-hover-decoration == none, null, none);
  }

  @each $breakpoint, $container-margin in $container-fluid-margin-widths {
    @include media-breakpoint-up($breakpoint) {
      right: $container-margin;
      bottom: map-get($o-scroll-up-bottom, $breakpoint);
    }
  }

  &::after {
    display: inline-block;
    width: $o-scroll-up-size;
    height: $o-scroll-up-size;
    margin-left: map-get($spacers, 2);
    content: "";
    background: $white escape-svg($o-scroll-up-icon) no-repeat center / 50% 50%;
    border: $border-width solid $black;

    /* rtl:ignore */
    transform: rotate(90deg);
  }

  &:hover::after,
  &:focus::after {
    background: $black escape-svg($o-scroll-up-icon-hover) no-repeat center / 50% 50%;
    border-color: $black;
  }

  &:active::after {
    background: $primary escape-svg($o-scroll-up-icon) no-repeat center / 50% 50%;
    border-color: $primary;
  }

  &.static {
    position: static;
    z-index: auto;
  }
}
